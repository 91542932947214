import React, { useEffect, useState } from 'react';
import { Menu, Dropdown, Badge, Avatar, List, Button, Tooltip } from 'antd';
import { MailOutlined, BellOutlined, WarningOutlined, CheckCircleOutlined } from '@ant-design/icons';
import moment from 'moment';
import io from 'socket.io-client';
import Flex from 'components/shared-components/Flex';
import { NotificationBellGray } from 'assets/svg/icon';
import { useHistory } from 'react-router-dom';
const socket = io(process.env.REACT_APP_NOTIFICATIONS_SOCKET); // Connect to Socket.IO server

// Get the appropriate icon based on notification type
const getIcon = (icon) => {
  const icons = {
    mail: <MailOutlined />,
    alert: <WarningOutlined />,
    check: <CheckCircleOutlined />,
  };
  return icons[icon] || <MailOutlined />;
};

// Tooltip for showing exact datetime on hover
const RelativeTimeWithTooltip = ({ datetime }) => (
  <Tooltip title={moment(datetime).format('YYYY-MM-DD HH:mm:ss')}>
    <span>{moment(datetime).fromNow()}</span>
  </Tooltip>
);


const redirectToPage = (service, id) => {
  switch (service) {
    case "Ecommerce":
      return `/app/pages/orders/showOrder/${id}`;

    case "Matting":
      return `/app/pages/request-service-list`;

    case "Haraj":
      return `/app/pages/haraj/ads/adDetails/${id}`;

    default:
      return "/";


  }
}
// Notification List Component
const NotificationList = ({ notifications, onClear, onMarkAsRead, history }) => (
  <>
    <div className="nav-notification-header d-flex justify-content-between align-items-center">
      <h4 className="mb-0">Notifications</h4>
      <Button type="link" onClick={onClear} size="small">
        Clear All
      </Button>
    </div>
    <div className="nav-notification-body">
      {notifications.length > 0 ? (
        <List
          size="small"
          itemLayout="horizontal"
          dataSource={notifications}
          renderItem={(item, index) => (
            <List.Item
              className="list-clickable"
              onClick={() => {
                console.log(item)
                onMarkAsRead(index, item.notificationId); // Mark the notification as read
                history.push(redirectToPage(item?.service, item?.mattingRequestId || item?.checkoutId || item?.postId)); // Redirect to the notification's related page
              }}
              actions={[
                // <Button
                //   key="mark-read"
                //   type="link"
                //   size="small"
                //   onClick={() => onMarkAsRead(index)}
                // >
                //   Mark as Read
                // </Button>,
              ]}
            >
              <Flex alignItems="center" >
                <div className="pr-3">
                  {item.img ? (
                    <Avatar src={item.img} />
                  ) : (
                    <Avatar className={`ant-avatar-${item.type}`} icon={getIcon(item.icon)} />
                  )}
                </div>
                <div className="mr-3">
                  <span className="font-weight-bold text-dark">{item.name}</span>{' '}
                  <span className="text-gray-light">{item.desc}</span>{' '}
                  <span
                    style={{
                      color: 'var(--1ry, #973F2A)',
                      fontFamily: 'Almarai',
                      fontSize: '14px',
                      fontStyle: 'normal',
                      fontWeight: '400',
                      lineHeight: '130%',
                    }}
                  >
                    {item.service}
                  </span>{' '}
                  <span
                    style={{
                      display: 'inline-block',
                      marginLeft: '4px',
                      width: '10px',
                      height: '10px',
                      borderRadius: '50%',
                      backgroundColor: 'var(--1ry, #973F2A)',
                    }}
                  ></span>
                </div>
                <small className="ml-auto">
                  <RelativeTimeWithTooltip datetime={item.time} />
                </small>
              </Flex>
            </List.Item>
          )}
        />
      ) : (
        <div className="empty-notification">
          <img
            src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
            alt="empty"
          />
          <p className="mt-3">You have viewed all notifications</p>
        </div>
      )}
    </div>
  </>
);

// Main NavNotification Component
export const NavNotification = () => {
  const [visible, setVisible] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const history = useHistory();

  // Handle dropdown visibility
  const handleVisibleChange = (flag) => setVisible(flag);

  // Clear all notifications
  const handleClearNotifications = () => {
    // Emit the event to clear notifications
    const token = localStorage.getItem('auth_token'); // Replace with actual receiver ID
    socket.emit('mark_all_notifications_as_read', { token });
  };

  // Mark a notification as read
  const handleMarkAsRead = (index, notificationId) => {
    // Emit the event to mark notification as read
    const token = localStorage.getItem('auth_token'); // Replace with actual receiver ID
    socket.emit('mark_one_notification_as_read', { token, notificationId });

    const updatedNotifications = [...notifications];
    updatedNotifications.splice(index, 1); // Remove the notification
    setNotifications(updatedNotifications);
  };

  useEffect(() => {
    // Connect and listen to the Socket.IO server
    socket.on('connect', () => {
      console.log('Connected to Socket.IO server');

      // Emit event to request all unread notifications
      const token = localStorage.getItem('auth_token'); // Replace with actual receiver ID
      socket.emit('get_all_unread_notifications', { token });
    });

    socket.on('connect_error', (error) => {
      console.error('Connection error:', error);
    });

    // Listen for the response containing unread notifications
    socket.on('get_all_unread_notifications', (notifications) => {
      console.log('Received unread notifications:', notifications);

      // Update state with unread notifications
      setNotifications((prev) => [
        ...prev,
        ...notifications.map((notification) => ({
          notificationId: notification?._id,
          service: notification?.data?.service,
          mattingRequestId: notification?.data?.mattingRequestId,
          checkoutId: notification?.data?.checkoutId,
          postId: notification?.data?.postId,
          name: notification?.data?.addedBy,
          desc: notification?.data?.message,
          img: notification?.data?.userProfile,
          icon: 'mail', // Customize based on your data
          time: notification?.createdAt,
          type: notification?.data?.type,
        })),
      ]);
    });

    // Listen for new notifications in real-time
    socket.on('notification', (data) => {
      console.log('New notification:', data);
      setNotifications((prev) => [
        {
          notificationId: notification?._id,
          name: data?.notification?.data?.addedBy,
          desc: data?.notification?.data?.message,
          img: data?.notification?.data?.userProfile,
          icon: 'mail', // Customize based on your data
          time: data?.notification?.createdAt,
          type: data?.notification?.data?.type,
          service: data?.notification?.data?.service,
          mattingRequestId: data?.notification?.data?.mattingRequestId,
          checkoutId: data?.notification?.data?.checkoutId,
          postId: data?.notification?.data?.postId,
        },
        ...prev
      ]);
    });

    // Listen for more notifications
    socket.on('get_all_notifications', (moreNotifications) => {
      console.log('Received more notifications:', moreNotifications);

      if (!moreNotifications || moreNotifications.length === 0) {
        console.log('No more notifications to load.');
        return;
      }

      setNotifications((prevNotifications) => [
        ...prevNotifications,
        ...moreNotifications.map((notification) => ({
          notificationId: notification?._id,
          service: notification?.data?.service,
          mattingRequestId: notification?.data?.mattingRequestId,
          checkoutId: notification?.data?.checkoutId,
          postId: notification?.data?.postId,
          name: notification?.data?.addedBy,
          desc: notification?.data?.message,
          img: notification?.data?.userProfile,
          icon: 'mail', // Customize based on your data
          time: notification?.createdAt,
          type: notification?.data?.type,
        })),
      ]);
    });


    // Listen for new notifications in real-time
    socket.on('mark_all_notifications_as_read', (data) => {
      console.log('New notification:', data);
      setNotifications([]);
    });

    // Listen for mark notifications as read.
    socket.on('mark_one_notification_as_read', (data) => {
      console.log('Marked as read:', data);
    })
    // Cleanup on unmount
    return () => {
      socket.off('connect');
      socket.off('connect_error');
      socket.off('get_all_unread_notifications');
      socket.off('notification');
      socket.off('get_all_notifications');
      socket.off('mark_all_notifications_as_read');
      socket.off('mark_one_notification_as_read')
    };
  }, []);


  const loadMoreNotifications = () => {
    const limit = 10;
    const skip = notifications.length;

    console.log('loadMoreNotifications', { skip, limit });

    // Emit the event to fetch more notifications
    const token = localStorage.getItem('auth_token'); // Replace with actual receiver ID
    socket.emit('get_all_notifications', { token, limit, skip });
  };

  // Notification dropdown content
  const notificationList = (
    <div
      className="nav-dropdown nav-notification"
      style={{
        width: '700px', // Adjust the width as desired
        maxHeight: '500px', // Limit the height for overflow
        overflowY: 'auto', // Add scrollbar if content overflows
        boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)', // Optional: Add subtle shadow for better appearance
        borderRadius: '8px', // Optional: Add rounded corners
        backgroundColor: '#fff', // Ensure background is white
        border: '1px solid #f0f0f0', // Optional: Add a light border for structure
      }}
    >
      {/* Notification List Component */}
      <NotificationList
        notifications={notifications}
        onClear={handleClearNotifications}
        onMarkAsRead={handleMarkAsRead}
        history={history}
      />

      {/* Footer for "View All" */}
      {notifications.length > 0 && (
        <div
          className="nav-notification-footer"
          style={{
            paddingTop: '8px', // Add spacing at the top
            borderTop: '1px solid #f0f0f0', // Add a separator line
            display: 'flex', // Use Flexbox
            justifyContent: 'center', // Center horizontally
            alignItems: 'center', // Center vertically
            height: '60px', // Set a fixed height for the footer
          }}
        >
          <button
            className="d-block"
            onClick={loadMoreNotifications}
            style={{
              color: '#1890ff', // Use primary color for the button
              fontWeight: '500',
              border: 'none',
              backgroundColor: 'transparent',
              cursor: 'pointer',
              padding: '8px 16px',
            }}
          >
            Load More
          </button>
        </div>
      )}
    </div>
  );


  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item>
          <Badge count={notifications.length} offset={[-5, 15]} style={{
            display: 'flex',
            justifyContent: "center",
            alignItems: "center"
          }}>
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                width: "40px", // Circle size
                height: "40px",
                borderRadius: "50%",
                backgroundColor: "#E5E7EB", // Gray background
                transform: "translateY(5px)"
              }}
            >
              <NotificationBellGray className="nav-icon mx-auto" type="bell" />
            </div>

          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  );
};

export default NavNotification;
